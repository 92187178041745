const colors = {
  primary: 'hsla(224, 93%, 49%, 1)',
  primaryLight: 'hsla(224, 93%, 65%, 1)',
  secondary: 'hsla(119, 100%, 22%, 1)',
  secondaryLight: 'hsla(119, 100%, 30%, 1)',
  red: 'hsla(15, 100%, 35%, 1)',
  redLight: 'hsla(15, 100%, 45%, 1)',
  yellow: 'hsla(44, 93%, 49%, 1)',
  yellowLight: 'hsla(44, 93%, 59%, 1)',
  bg: 'hsla(224, 10%, 95%, 1)',
  grey: {
    dark: 'hsla(224, 5%, 10%, 1)',
    default: 'hsla(224, 5%, 20%, 1)',
    light: 'hsla(224, 5%, 70%, 1)',
    veryLight: 'hsla(224, 5%, 90%, 1)',
    ultraLight: 'hsla(224, 5%, 95%, 1)',
  },
  white: 'white',
};

const transitions = {
  normal: '0.5s',
};

const fontFamily = {
  headline: `'Open Sans', 'News Cycle', 'Rubik'`,
  body: `'Roboto', 'Work Sans'`,
  serif: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', serif`,
  sansSerif: `'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
};

const breakpoint = {
  screen: '1800px',
  tablet: '1200px',
  phone: '600px',
};

const fontSize = {
  small: '0.9rem',
};

const shadows = {
  small: '0 0 4px rgba(0, 0, 0, .125)',
  large: '0 0 24px rgba(0, 0, 0, .125)',
};

const theme = {
  colors,

  transitions,
  fontSize,

  breakpoint,
  fontFamily,

  shadows,

  maxWidth: '1000px',
  baseFontSize: '17px',
};

export default theme;

import React from 'react';
import { Link } from 'gatsby';

const SiteFooter = props => (
  <div className="text-md text-blue-900 text-center p-5 bg-gray-300">
    &copy; 2020 Ryan Bethel, Built with <a href="https://www.gatsbyjs.org/">Gatsby</a>. <br />
    <div className="flex flex-col md:flex-row  justify-center  ">
      <Link to="/">ryanbethel.org</Link>
      <span className="px-2 hidden md:inline "> &mdash; </span>
      <a href="mailto:ryan.bethel@gmail.com">ryan.bethel@gmail.com</a>
      <span className="px-2 hidden md:inline "> &mdash; </span>
      <a
        href="https://twitter.com/ryanbethel?ref_src=twsrc%5Etfw"
        class="twitter-follow-button"
        data-show-count="false"
      >
        Follow @ryanbethel
      </a>
      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </div>
  </div>
);

export default SiteFooter;
